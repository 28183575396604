import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";
import App from "./App.tsx";
import "./index.css";

const version = import.meta.env.VITE_APP_VERSION || "unknown";

Sentry.init({
  dsn: "https://dbfe6a73e1174d8489ad9ca0780c25f5@o1163214.ingest.us.sentry.io/4504533298249728",
  release: version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        "api.famepeak.io",
        "api-sandbox.famepeak.io",
      ],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      networkCaptureBodies: true,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.MODE,
});

const root = createRoot(document.getElementById("root")!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

document.dispatchEvent(new Event('prerender-trigger'));
